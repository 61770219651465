<template>
  <div class="home">
    <!-- <Navbar />
    <Sidebar /> -->
    <!-- class="main product__management" -->
    <section
      style="display: flex; flex-direction: column;"
    >
      <div style="display: flex;">
        <b-form-group
          id="input-group-9"
          :label="$t('FilterRouteByDate')"
          label-for="input-9"
        >
          <div
            class="year"
          >
            <button
              @click="previousDay"
            >
              <b-icon-arrow-left />
            </button>
            <date-picker
              v-model="selectedDate"
              value-type="format"
              class="form-control"
              format="DD-MM-YYYY"
              type="date"
              placeholder="Select Date"
              style="width:200px; border: none;"
              :lang="lang"
              :clearable="false"
            />
            <button
              style="margin-left: 5px"
              @click="nextDay"
            >
              <b-icon-arrow-right />
            </button>
          </div>
        </b-form-group>
        <b style="display: flex; align-items: center; margin-top: 8px; margin-right: 20px; font-size: 20px;">
          {{ $t(dateToString) }}
        </b>
        <b-form-group
          id="input-group-2"
          :label="$t('Manager')"
          label-for="input-2"
          style="width: 16%;"
        >
          <b-form-select
            v-model="manager"
            required
            :clearable="false"
            style="width:100%;padding: 8.5px; color: #82868c; border-radius: 8px; border: none; box-shadow: 0px 0px 10px rgb(40 41 61 / 14%);"
            :options="selectManager"
            label="userFullName"
            :reduce="(e) => e.userId"
            :placeholder="$t('Select')"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <div style="display: flex; flex-direction: row; align-items: center; padding-left: 20px; gap: 15px;">
          <div style="display: flex; flex-direction: row-reverse; gap: 0px; align-items: center;">
            <p style="margin-bottom: 0px; font-weight: bold;">
              {{ $t('Internal') }}
            </p>
            <span style="width:30px;height:30px;border-radius: 50%;font-weight:normal;background-color: rgb(143, 188, 143); color: black; text-align: left; flex: 1; margin-right: 10px;padding: 7px;" />
          </div>
          <div style="display: flex; flex-direction: row-reverse; gap: 0px; align-items: center;">
            <p style="margin-bottom: 0px; font-weight: bold;">
              {{ $t('External') }}
            </p>
            <span style="width:30px;height:30px;border-radius: 50%;font-weight:normal;background-color: rgb(255, 215, 0); color: black; text-align: left; flex: 1; margin-right: 10px;padding: 7px;" />
          </div>
        </div>
      </div>
      <div style="display: flex;">
        <table class="filialeOverTab">
          <thead>
            <tr>
              <th
                v-for="header in getStoreManagementOverview"
                :key="header.storeGLN"
                class="table-header"
                href="#"
                tabindex="0"
                :title="text"
              >
                <span style="color: #0071AE;">{{ header.storeName }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="index in rowCount"
              :key="index"
            >
              <td
                v-for="store in getStoreManagementOverview"
                :key="store.storeGLN"
                style="width: 12.5%;"
              >
                <div v-if="store.storeTruckAndStaffOverviews && store.storeTruckAndStaffOverviews.length >= index">
                  <div :class="['truckBox', { 'withRedBorder': true, 'withGreenBorder': store.storeTruckAndStaffOverviews[index - 1].staffNames.length === 0 }]">
                    <p
                      class="header"
                      :style="store.storeTruckAndStaffOverviews[index - 1].isExchanged ? 'background-color: rgb(255, 215, 0)':'background-color:  rgb(143, 188, 143)'"
                      style="text-align: center; background-color: lightgrey;border-radius: 10px 10px 0px 0px; margin-bottom: 10px;"
                    >
                      <b>{{ store.storeTruckAndStaffOverviews[index - 1].registrationPlate }}</b>
                    </p>
                    <div
                      v-for="(staffName, staffIndex) in store.storeTruckAndStaffOverviews[index - 1].staffNames"
                      :key="staffIndex"
                      class="employeeBox"
                      :style="staffName.employeeType == 'Internal' ? 'background-color: #8FBC8F ':' background-color: #FFD700'"
                    >
                      <span
                        :style="staffName.employeeType == 'Internal' ? 'background-color: #8FBC8F ':' background-color: #FFD700'"
                      >
                        {{ staffName.userFullName }}
                        <p v-if="staffName.employeeType == 'Internal'">
                          <!-- ({{ $t('Internal') }}) -->
                          <!-- <span class="green" /> -->
                        </p>
                        <p v-else>
                          <!-- ({{ $t('External') }}) -->
                          <!-- <span class="yellow" /> -->
                        </p>
                        <!-- <br> -->
                      </span>
                    </div>
                    <div
                      v-if="store.storeTruckAndStaffOverviews[index - 1].staffNames.length === 0"
                      style=" background-color: white; min-height: 71px; border-radius: 0px 0px 10px 10px;"
                    >
                      <span>{{ $t('No') }} {{ $t('Employees') }}</span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <td
            v-for="store in getStoreManagementOverview"
            :key="store.storeGLN"
            class="truckBox1"
          >
            <p style="text-align: left;margin-bottom: 0px;display: flex; justify-content: space-between;background-color: #F3F3F3; border-radius: 5px 5px 5px 5px;margin-top: 10px;padding: 10px 0px 10px 0px; ">
              <span style="color:#0071AE; font-weight: 600;padding-left: 7px;text-align: left;">{{ $t('Truck') }}</span> <br><b style="color:#0071AE;padding-right: 7px;">{{ store.storeDailyOverviewCalculations.numberOfTrucks }}</b>
            </p>
            <p style="text-align: left;;margin-bottom: 0px;display: flex; justify-content: space-between;background-color: #F3F3F3; border-radius: 5px 5px 5px 5px;margin-top: 10px;margin-bottom: 10px;padding: 10px 0px 10px 0px; ">
              <span style="color:#0071AE; font-weight: 600;padding-left: 7px;text-align: left;">{{ $t('Estimated') }} <br> {{ $t('Employees') }}</span> <br> <b style="color:#0071AE;padding-right: 7px;padding-top: 10px;">{{ store.storeDailyOverviewCalculations.numberOfEmployees }}</b>
            </p>
            <div
              style="display: flex; justify-content: space-between;background-color: #F3F3F3; border-radius: 5px 5px 5px 5px;margin-top: 10px;margin-bottom: 10px;padding: 10px 0px 10px 0px; "
            >
              <span style="color:#0071AE; font-weight: 600;padding-left: 7px;text-align: left;">{{ $t('Estimated') }} <br> {{ $t('Earnings') }}</span> <br> <b style="color:#0071AE;padding-right: 7px;padding-top: 10px;">{{ store.storeDailyOverviewCalculations.estimatedEarnings }}</b>
            </div>
            <div
              style="display: flex; justify-content: space-between;background-color: #F3F3F3; border-radius: 5px 5px 5px 5px;margin-top: 10px;margin-bottom: 10px;padding: 10px 0px 10px 0px; "
            >
              <span style="color:#0071AE; font-weight: 600;padding-left: 7px;text-align: left;">{{ $t('Current') }} <br>  {{ $t('Earnings2') }}</span> <br><b style="color:#0071AE;padding-right: 7px;padding-top: 10px;">{{ store.storeDailyOverviewCalculations.currentEarnings }}</b>
            </div>
          </td>
        </table>
        <table class="filialeExternal">
          <div>
            <thead style="border-left: 1px solid lightgrey;height: 44px !important;background-color: #F3F3F3;">
              <th style="text-align: left; color:#0071AE;;width: 50%;">
                {{ $t('EmployeeName') }}
              </th>
              <th style="text-align: left;;border-left: 1px solid lightgrey;color:#0071AE;width: 50%;">
                {{ $t('VacationLeaveReq') }}
              </th>
            </thead>
            <tbody
              v-if="getVacationOverview"
              style="background-color: #F3F3F3;"
            >
              <tr
                v-for="name in getVacationOverview.storeDailyVacation"
                :key="name.id"
              >
                <td style="border: 1px solid lightgrey;color: #141414;font-weight: 600;">
                  {{ name.fullName }}
                </td>
                <td
                  v-if="name.vacationType == 'Vacation'"
                  style="border: 1px solid lightgrey;color: #141414;font-weight: 600;"
                >{{ $t('Vacation') }}</td>
                <td
                  v-if="name.vacationType == 'SickLeave'"
                  style="border: 1px solid lightgrey;color: #141414;font-weight: 600;"
                >{{ $t('AddNew19') }}</td>
                <td
                  v-if="name.vacationType == 'AccidentLeave'"
                  style="border: 1px solid lightgrey;color: #141414;font-weight: 600;"
                >{{ $t('AddNew20') }}</td>
                <td
                  v-if="name.vacationType == 'Education'"
                  style="border: 1px solid lightgrey;color: #141414;font-weight: 600;"
                >{{ $t('AddNew21') }}</td>
                <td
                  v-if="name.vacationType == 'SpecialLeave'"
                  style="border: 1px solid lightgrey;color: #141414;font-weight: 600;"
                >{{ $t('AddNew22') }}</td>
                <td
                  v-if="name.vacationType == 'Maternity'"
                  style="border: 1px solid lightgrey;color: #141414;font-weight: 600;"
                >{{ $t('AddNew23') }}</td>
                <td
                  v-if="name.vacationType == 'Appointment'"
                  style="border: 1px solid lightgrey;color: #141414;font-weight: 600;"
                >{{ $t('AddNew24') }}</td>
                <td
                  v-if="name.vacationType == 'DeathCase'"
                  style="border: 1px solid lightgrey;color: #141414;font-weight: 600;"
                >{{ $t('AddNew46') }}</td>

              </tr>

            </tbody>
            <div style="padding: 10px 0px 10px 0px; background-color:#F3F3F3 ; display: flex;justify-content: space-between; text-align: left; margin-top: 20px;">
              <b style="color:#0071AE ; padding-left: 7px;"> {{ $t('External') }} {{ $t('Employees') }} </b>  <br> <b
                v-if="getVacationOverview"
                style="color: #0071AE; font-weight: normal;padding-right: 7px;"
              >{{ getVacationOverview.externalCounter }}</b>
            </div>
          </div>
          <td style="text-align: left;margin-left: 5px;">
            <p style="text-align: left;margin-bottom: 0px; background-color:rgb(243, 243, 243);padding: 10px 0px 10px 0px ;display: flex; justify-content: space-between;">
              <span style="text-align: left;color: #0071AE; font-weight: 600;padding-left: 5px;">{{ $t('Date') }}</span> <b style="color: #0071AE; padding-right: 5px;">{{ selectedDate }}</b>
            </p>
            <p style="margin-top: 10px; margin-bottom: 10px; text-align: left;;margin-bottom: 0px; background-color:rgb(243, 243, 243);padding: 10px 0px 10px 0px ;display: flex; justify-content: space-between;">
              <span style="text-align: left;color: #0071AE; font-weight: 600;padding-left: 5px;">{{ $t('Trucks') }}</span> <br> <b style="color: #0071AE; padding-right: 5px;">{{ totalTrucks }}</b>
            </p>
            <div
              style="margin-top: 10px; margin-bottom: 10px; background-color:rgb(243, 243, 243);padding: 10px 0px 10px 0px ;display: flex; justify-content: space-between;"
            >
              <span style="text-align: left;color: #0071AE; font-weight: 600;padding-left: 5px;"> {{ $t('Total') }} <br>{{ $t('Employees') }} </span> <br> <b style="color: #0071AE; padding-right: 5px;padding-top: 10px;">{{ totalEmployees }}</b>
            </div>
            <div
              style=" margin-top: 10px; margin-bottom: 10px;background-color:rgb(243, 243, 243);padding: 10px 0px 10px 0px ;display: flex; justify-content: space-between;"
            >
              <span style="text-align: left;color: #0071AE; font-weight: 600;padding-left: 5px;"> {{ $t('Total') }} <br> {{ $t('EstimatedEarnings') }} </span> <br> <b style="color: #0071AE; padding-right: 5px;padding-top: 10px;">{{ totalEstimatedEarnings }}</b>
            </div>
            <div
              style=" margin-top: 10px; margin-bottom: 10px;background-color:rgb(243, 243, 243);padding: 10px 0px 10px 0px ;display: flex; justify-content: space-between;"
            >
              <span style="text-align: left;color: #0071AE; font-weight: 600;padding-left: 5px;">{{ $t('Total') }}  <br>{{ $t('CurrentEarnings') }} </span> <br> <b style="color: #0071AE; padding-right: 5px;padding-top: 10px;">{{ totalCurrentEarnings.toFixed(2) }}</b>
            </div>
          </td>
        </table>
      </div>
    </section>
    <!-- <div
      v-if="!getIsLoading"
      style="padding-left: 18%"
    >
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue';
import Navbar from '@/components/Navbar.vue';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';


export default {
  name: 'FilialeOverview',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Sidebar,
    DatePicker,
    // eslint-disable-next-line vue/no-unused-components
    Navbar,
  },
  data() {
    return {
      filialeIdShow: '',
      routeIdShow: '',
      truckShow: {},
      selectedDate: moment().format('DD-MM-YYYY'),
      time2: null,
      isInitialRender: true,
      manager: null,
      employee1: 'Euron Ramadani',
      employee2: 'Bajram Latifaj',
      dateToString: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      text: [
      ],
    };
  },
  computed: {
    ...mapGetters(['getIsLoading', 'getFreeManagers', 'getStoreManagementOverview', 'getVacationOverview', 'getFreeRegularEmployee']),
    selectManager() {
      return this.getFreeManagers.map((option) => {
        return {
          value: option.userId,
          text: option.userFullName,
        }
      })
    },
    totalTrucks() {
      return this.getStoreManagementOverview.reduce((sum, store) => { return sum + store.storeDailyOverviewCalculations.numberOfTrucks }, 0);
    },
    totalEmployees() {
      return this.getStoreManagementOverview.reduce((sum, store) => { return sum + store.storeDailyOverviewCalculations.numberOfEmployees }, 0);
    },
    totalEstimated() {
      return this.getStoreManagementOverview.reduce((sum, store) => { return sum + store.storeDailyOverviewCalculations.numberOfEmployees }, 0);
    },
    totalEstimatedEarnings() {
      return this.getStoreManagementOverview.reduce((sum, store) => { return sum + store.storeDailyOverviewCalculations.estimatedEarnings }, 0);
    },
    totalCurrentEarnings() {
      return this.getStoreManagementOverview.reduce((sum, store) => { return sum + store.storeDailyOverviewCalculations.currentEarnings }, 0);
    },
    rowCount() {
      let maxRowCount = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const store of this.getStoreManagementOverview) {
        if (store.storeTruckAndStaffOverviews && store.storeTruckAndStaffOverviews.length > maxRowCount) {
          maxRowCount = store.storeTruckAndStaffOverviews.length;
        }
      }
      return maxRowCount;
    },
  },
  watch: {
    selectedDate: {
      handler(value) {
        // this.changeLoadingtoTrue(false);
        this.dateToString = moment(value, 'DD-MM-YYYY').format('dddd')
        if (!this.isInitialRender) {
          const time = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD')

          this.loadStoreManagementOverview({
            userId: this.manager,
            startDate: time,
          }).then(() => {
          // this.changeLoadingtoTrue(true);
          });
          // this.changeLoadingtoTrue(false);

          this.loadVacationOverview({
            managerId: this.manager,
            date: time,
          });
        } else {
          this.isInitialRender = false;
        }
      },
    },
    manager: {
      handler() {
        // this.changeLoadingtoTrue(false);
        if (!this.isInitialRender) {
          const time = moment(this.selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD')


          // console.log('date', value);
          this.loadStoreManagementOverview({
            userId: this.manager,
            startDate: time,
          })
          this.loadVacationOverview({
            managerId: this.manager,
            date: time,
          });
        } else {
          this.isInitialRender = false;
        }
        // this.changeLoadingtoTrue(true);
      },
    },
  },
  mounted() {
    // this.changeLoadingtoTrue(false);
    this.dateToString = moment(this.selectedDate, 'DD-MM-YYYY').format('dddd')
    const time = moment(this.selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD')

    this.loadFreeManagers().then(() => {
      if (this.getFreeManagers.length > 0) {
        const firstManagerId = this.getFreeManagers[0].userId;
        this.manager = firstManagerId; // Set the v-model of vue-select to the first manager
        this.loadStoreManagementOverview({
          userId: firstManagerId,
          startDate: time,
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
        // this.changeLoadingtoTrue(false);

        this.loadVacationOverview({
          managerId: firstManagerId,
          date: time,
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
        // this.changeLoadingtoTrue(false);
      }
    });
  },
  methods: {
    ...mapActions(['changeLoadingtoTrue', 'loadStoreManagementOverview', 'loadFreeManagers', 'loadVacationOverview', 'loadFreeRegularEmployee', 'changeLoadingtoTrue']),
    storeID(value) {
      // console.log(value)
      // this.changeLoadingtoTrue(false);
      const time = moment(this.selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD')



      this.loadFreeRegularEmployee({
        storeId: value,
        startShift: time,
        endShift: time,
        includeTeamLeaders: true,
        includeManager: false,
      }).then((res) => {
        console.log(res);
        // this.changeLoadingtoTrue(true);
      });

      this.text = []
      for (let i = 0; i < this.getFreeRegularEmployee.length; i++) {
        this.text.push(`${this.getFreeRegularEmployee[i].userFullName} (${this.getFreeRegularEmployee[i].employeeType})  `)
      }
    },
    time1(value) {
      // console.log('time1', value)
      this.time2 = value
    },
    filteredStores(storeName) {
      return this.getStoreManagementOverview.filter((store) => { return store.storeName === storeName });
    },
    previousDay() {
      this.selectedDate = moment(this.selectedDate, 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY');
    },
    nextDay() {
      this.selectedDate = moment(this.selectedDate, 'DD-MM-YYYY').add(0.5, 'days').format('DD-MM-YYYY');
    },
    truckId(item) {
      this.truckShow = item;
    },
    dressId(id) {
      this.filialeIdShow = id;
      this.routeIdShow = ''
      this.truckShow = {}
    },
    routeId(id) {
      this.routeIdShow = id;
      this.truckShow = {}
    },
  },
};
</script>

<style lang="scss" scoped>
.green {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid green;
  border-radius: 5px;
}

.filialeExternal{
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: space-between;
}

.filialeOverTab{
  width: 100%;
}

#input-group-9{
  margin-bottom: 10px;
  width: 20%;
}
.green::after{
  border: 1px solid green !important;
}
.yellow {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid red;
  border-radius: 5px;
}
.yellow::after{
  border: 1px solid red !important;
}
.truckBox{
 border: 1px solid rgb(246, 147, 147);
 display: flex;
 flex-direction: column;
//  justify-content: space-between;
 border-radius: 12px !important;
 text-align: left;
//  background-color:  rgb(183, 255, 177);
 min-height: 155px;
}
.truckBox.withRedBorder {
  border: 2px solid rgb(8, 180, 22);
}

span{
  color: black;
}
p{
  color: black;
}
table td{
  color: black;
}

.truckBox.withGreenBorder {
  border: 2px solid rgb(246, 147, 147);
}
.truckBox2{
 border: 1px solid rgba(0, 0, 0, 0.263);
 border-radius: 12px !important;
 text-align: left;
 background-color:  rgb(183, 255, 177);
 min-height: 95px;
}
.truckBox1{
 border-radius: 10px !important;
 text-align: left;
 min-height: 95px;
}
.externalEmployee {
  background-color: #f8e788;
 border-radius: 0px 0px 12px 12px !important;
}
.employeeBox{
  padding-top: 5px;
  padding-bottom: 10px;
  // background-color:rgb(183, 255, 177);
//  border-radius: 0px 0px 12px 12px !important;
}
.employeeBox span {
  display: inline-block;
  position: relative;
}

.employeeBox span::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 5px;
  width: calc(100% - 10px); /* Adjust the width to include the shorter sides */
  height: 2px;
   /* Set the underline color */
  border: 0.5px solid #A5A5A5;
  opacity: 1;
  }
.table-header {
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}
.product__management .hide-on-products {
  display: none;
}

@media screen and (min-width: 1042px){
  .main{
    padding: 90px 10px 0 10px;
  }
}

@media screen and (max-width: 1041px){

  .main {
    padding: 90px 0.75rem 0 0.75rem;
  }
  #input-group-9{
  width: 22%;
  }
  .filialeExternal{
    font-size: 12px;
  }

  .filialeOverTab{
    font-size: 12px;
  }
}

@media screen and (max-width: 1500px){
  .filialeExternal{
    font-size: 14px;
  }

  .filialeOverTab{
    font-size: 14px;
  }
}
@media screen and (max-width: 896px) {
  .main {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.year {
  width: 100%;
  display: flex;
  // justify-content: space-around;
  align-items: center;
  button {
    width: 36px !important;
    height: 36px;
    display: flex;
    margin-right: 5px;
    border-radius: 6px !important;
    justify-content: center;
    border: #c3d0dd 1px solid;
    background-color: #e9ecef;
    align-items: center;
  }
  h5 {
    border: #b4b4b4 2px solid;
    border-radius: 6px;
    background-color: whitesmoke;
    width: 40%;
    height: 36px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
}
</style>
